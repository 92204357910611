import React, { useState } from "react";
import Marquee from "react-fast-marquee";

// Import images
import webDevIcon from '../img/webDev.png';
import webDevIconActive from '../img/webDevColored.png';
import uxDesignIcon from '../img/uxDesign.png';
import uxDesignIconActive from '../img/uxDesignColor.png';
import mobDevIcon from '../img/mobDev.png';
import mobDevIconActive from '../img/mobDevColored.png';
import landingPageIcon from '../img/landingPage.png';
import landingPageIconActive from '../img/landingPageColored.png';
import logoDesignIcon from '../img/logoDesign.png';
import logoDesignIconActive from '../img/logoDesignColored.png';
import ecommerceSitesIcon from '../img/ecommerceSites.png';
import ecommerceSitesIconActive from '../img/ecommerceSitesColored.png';
import businessSitesIcon from '../img/businessSites.png';
import businessSitesIconActive from '../img/businessSitesColored.png';
import socialBannerIcon from '../img/socialBanner.png';
import socialBannerIconActive from '../img/socialBannerColored.png';


const workComp = ['Company Profile', 'UI Design', 'Mobile Application', 'Powerpoint Presentation', 'Landing Page', 'Ecommerce Website', 'Business Website', 'Business Website']

const expertiseCards = [
  { icon: webDevIcon, iconActive: webDevIconActive, name: 'Web Development' },
  { icon: uxDesignIcon, iconActive: uxDesignIconActive, name: 'UX/UI Design' },
  { icon: mobDevIcon, iconActive: mobDevIconActive, name: 'Mobile Development' },
  { icon: landingPageIcon, iconActive: landingPageIconActive, name: 'Landing Pages' },
  { icon: logoDesignIcon, iconActive: logoDesignIconActive, name: 'Logo Design' },
  { icon: ecommerceSitesIcon, iconActive: ecommerceSitesIconActive, name: 'E-commerce Sites' },
  { icon: businessSitesIcon, iconActive: businessSitesIconActive, name: 'Business Websites' },
  { icon: socialBannerIcon, iconActive: socialBannerIconActive, name: 'Social Banners' }
];
const websiteAlert = ['"Please note that this website is currently not fully responsive. We are working on an upgraded version that will introduce new features shortly. In the meantime, some links may not function as expected. Thank you for your understanding."']

{/* ALERT BAR */}
export const AlertBar = () => {
  return (
    <Marquee className="alertBar">
      &nbsp;{websiteAlert}&nbsp;
    </Marquee>
  );
}
{/* ALERT BAR */}

{/* TICKER BAR */}
export const TickerBar = () => {
  return (
    <Marquee className="ticker">
      {workComp.map((item, index) => (
           <section key={index}>
            <span className="circle-pointer"></span>
            <span>{item}</span>
           </section>
      ))}
    </Marquee>
  );
}
{/* TICKER BAR */}

{/* EXPERTISE CARDS */}
export const ExpertiseCards = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
return (
  <Marquee className="expertiseCards" direction='right'>
    {expertiseCards.map((item, index) => (
         <section 
         key={index}  
         onMouseEnter={() => setHoveredIndex(index)}
         onMouseLeave={() => setHoveredIndex(null)}>
          <div className="icon">
              <img 
              src={hoveredIndex === index ? item.iconActive : item.icon}
              alt={item.name}/>
          </div>
          <div className="iconText">{item.name}</div>
         </section>
    ))}
  </Marquee>
);
}
{/* EXPERTISE CARDS */}

{/* LETS WORK */}
export const LetsWork = ({ message, repeatCount, className, directions = ['right', 'left'], marqueeCount, spanClasses = ['class1', 'class2']}) => {

  const generateMessages = (marqueeIndex) => {
    return Array.from({ length: repeatCount }).map((_, spanIndex) => {
      // Determine class based on both marquee index and span index
      const spanClass = spanClasses[(spanIndex + marqueeIndex) % spanClasses.length];
      return (
        <span key={spanIndex} className={spanClass}>
          {message}
        </span>
      );
    });
  };

 
  return (
    <section>
    {Array.from({ length: marqueeCount }).map((_, index) => (
      <Marquee key={index} className={className} direction={directions[index % directions.length]}>
        {generateMessages(index)}
      </Marquee>
    ))}
  </section>
  );
}
{/* LETS WORK */}