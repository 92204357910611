import React, { useEffect, useState }  from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import Wave from 'react-wavify'

// Import images
import expertiseIcon from '../img/expertiseIcon.png';
import innovationIcon from '../img/innovationIcon.png';
import provenResults from '../img/provenResults.png';
import passionIcon from '../img/passionIcon.png';
import userCentric from '../img/userCentric.png';
import collaborativeIcon from '../img/collaborativeIcon.png';
import globalReach from '../img/globalReach.png';


const whyUsObj = [
    {
        icon: expertiseIcon,
        heading: 'Expertise',
        content: 'Extensive experience in UX/UI design, web, and mobile development, delivering high-quality solutions.',
    },
    {
        icon: innovationIcon,
        heading: 'Innovation',
        content: 'Practical, intuitive designs tailored to meet your unique business needs and goals effectively.',
    },
    {
        icon: provenResults,
        heading: 'Proven Results',
        content: 'Successful CRM, fitness app, and e-commerce projects using various innovative technologies and designs.',
    },
    {
        icon: passionIcon,
        heading: 'Passion',
        content: 'Driven by innovation and excellence, continuously enhancing our skills and expertise for better results.',
    },
    {
        icon: userCentric,
        heading: 'User-Centric',
        content: 'Focused on creating intuitive solutions that enhance user satisfaction and overall engagement.',
    },
    {
        icon: collaborativeIcon,
        heading: 'Collaborative',
        content: 'Open communication and close client collaboration for accurate, vision-aligned project results.',
    },
    {
        icon: globalReach,
        heading: 'Global Reach',
        content: 'Based in India, offering cost-effective, high-quality solutions to clients worldwide.',
    },
]

const WhyUs = () => {
    let [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 767);

    let updateMedia = () => {
        setIsSmallScreen(window.innerWidth <= 576);
    };

    useEffect(() => {
        window.addEventListener('resize', updateMedia);
        // Cleanup event listener on component unmount
        return () => window.removeEventListener('resize', updateMedia);
    }, []);

    let waveOptions = isSmallScreen
        ? {
            height: 20,
            amplitude: 50,
            speed: 0.1,
            points: 4
        }
        : {
            height: 40,
            amplitude: 70,
            speed: 0.2,
            points: 6
        };
        return (
            <section className='whyUs'>
                <Wave fill='rgb(153, 167, 255)'
                        paused={false}
                        style={{ display: 'flex' }}
                        options={waveOptions}
                />
                <div className='whyUsContent'>
                    <Container>
                        <Row>
                        <Col className="multi-lineHeading leftHeading moveRight py-5" sm={12}>
                            <span className='dark-h'>Why</span>
                            <span className='light-h'>US?</span>
                        </Col>
                        <Col sm={12}className='textArea'>
                            Choosing the right partner for your digital projects is crucial to ensuring success and growth. Here’s why we stand out as your ideal choice:
                        </Col>
                        </Row>
                        <Row className='whyUsCards py-5'>
                            { whyUsObj.map((item,index) => (
                                <Col md={4} className="whyUsCard my-4 px-5">
                                <div className='cardHeading'>
                                    <span>{item.heading}</span>
                                    <span><img src={item.icon} alt={item.heading}/></span>
                                </div>
                                <div  className='cardContent'>{item.content}</div>
                            </Col>
                            ))
                            }
                        </Row>
                    </Container>
                </div>
        </section>
        )
}
export default WhyUs;