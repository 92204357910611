import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header.js';
import Footer from './components/Footer.js';
import Home from './components/Home.js';
import Logo from './img/logo.png';
import { AlertBar } from './components/MarqueTransitions';


const App = () => {
  // Example of dynamic content
  const dynamicMenuItems = ['Home', 'Expertise', 'Work', 'Resume', 'Contact'];  
  
  return (
    <div className="App position-relative">
        <AlertBar/>
        <Header logo={Logo} menuItems={dynamicMenuItems} />
        <Home />
        <Footer/>
    </div>
  );
}

export default App;
