import React, { useRef, useState } from 'react';
import { Form, Button, Modal, Spinner} from 'react-bootstrap';
import emailjs from '@emailjs/browser';

const ContactForm = () => {
    let form = useRef();
    let [showModal, setShowModal] = useState(false);
    let [loading, setLoading] = useState(false); 
    let [errorMessage, setErrorMessage] = useState('');
    let sendEmail = (e) => {
        e.preventDefault();
        setLoading(true);
        emailjs
        .sendForm('service_vukwv0b', 'template_altab38', form.current, {
            publicKey: 'au6bTE1VpQFrIsTmC',
        })
        .then(
            () => {
            form.current.reset();
            setShowModal(true);
            setErrorMessage(''); 
            },
            (error) => {
                setErrorMessage('Failed to send message. Please try again.');
            },
        ).finally(() => {
            setLoading(false); // Reset loading status
        });
    };
    let handleClose = () => setShowModal(false);
    return(
        <>
            <Form ref={form} onSubmit={sendEmail}>
                <Form.Group className="mb-3" controlId="formName">
                    <Form.Control type="text" placeholder="Name" name="user_name" required/>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formEmail">
                    <Form.Control type="email" placeholder="Email" name="user_email" required/>
                </Form.Group>


                <Form.Group className="mb-3" controlId="formSubject">
                    <Form.Control type="text" placeholder="Subject" name="user_subject" required/>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formSubject">
                    <Form.Control  as="textarea" rows={3} placeholder="Message" name="user_message" required/>
                </Form.Group>
            
                <Button variant="primary" type="submit" disabled={loading}> 
                    {loading ? <Spinner animation="border" size="sm" /> : 'Submit'}
                </Button>

                {errorMessage && <div className="text-danger">{errorMessage}</div>}
            </Form>
            {/* Modal for Success Notification */}
            <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Success!</Modal.Title>
            </Modal.Header>
            <Modal.Body>Your message has been sent successfully!</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
     </>
    );
};

export default ContactForm;