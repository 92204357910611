import Banner from './Banner.js'
import Carousel from './Carousel.js'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ExpertiseCards, LetsWork } from './MarqueTransitions';
import WhyUs from './WhyUs';
import WorkProcess from './WorkProcess';
import BannerImg from '../img/profileImg.png';
import mobAreaBg from '../img/mobAreaBg.png';
import crmImg from '../img/crmImg.png';
import mobImg from '../img/mobImg.png';
import webImg from '../img/webImg.png';
import arrowRgtImg from '../img/arrowRgtImg.png';
import arrowLftImg from '../img/arrowLftImg.png';
import arrowSgtUp from '../img/arrowSgtUp.png';

const ourProjectsData = [
    {
        content: 'We specialize in designing and developing Customer Relationship Management (CRM) systems that enhance business operations and improve customer interactions. Our CRMs are tailored to meet specific business needs, offering features like contact management, sales automation, and customer service tools, all integrated into a user-friendly interface.',
        buttonText: 'Case Studies',
        background: `url(${mobAreaBg}) center/cover no-repeat`,
        image: crmImg,
        imgAlt: 'CRM Image'
    },
    {
        content: 'Our experience in fitness app design includes creating comprehensive solutions that promote healthy lifestyles and facilitate user engagement. These apps are equipped with features such as workout tracking, diet planning, progress monitoring, and social sharing, all wrapped in an intuitive and engaging design.',
        buttonText: 'Case Studies',
        background:'#F8F8F8',
        image: mobImg,
        imgAlt: 'Mobile Image'
    },
    {
        content: 'We developed a comprehensive educational website that caters to learners at various levels, offering packages for beginners, intermediate, advanced, and professional users. The platform provides a wide range of resources, including free courses, in-depth courses, introductory courses, and eBooks, ensuring a robust and flexible learning experience tailored to meet diverse educational needs.',
        buttonText: 'Case Studies',
        background: `#fff`,
        image: webImg,
        imgAlt: 'Learing Website Image'
    },
];

  const bannerHeading = 'Hi!!! I am ';
  const lightHeading = 'UX & UI Developer';
  const bannerContent = 'We craft practical, logic-driven solutions with intuitive interfaces. Our passion fuels constant skill growth and innovation. From our base in India, we deliver high-quality, cost-effective solutions worldwide, blending local expertise with a global vision.' ;
  const expertiseContent = [
    {
        contentUp: 'With a rich and diverse background in UX/UI design, web development, and mobile development, we bring a holistic approach to crafting digital experiences. My expertise spans across various domains, including:',
        contentDown: 'My portfolio includes significant projects like CRM design and development, fitness app design, and e-commerce app development using React Native. Our primary objective is to offer practical solutions backed by sound logic and an intuitive user interface. Driven by a passion for innovation and a commitment to excellence, we continuously strive to expand our skills and stay at the forefront of industry trends.'
    }
  ];
const Home = () => {
  return (
    <div className="home">
        {/* BANNER SECTION */}
        <Banner img={BannerImg} heading={bannerHeading} lightHeading={lightHeading} content={bannerContent}/>
        {/* BANNER SECTION */}

        {/* EXPERTISE SECTION */}
        <section id="Expertise">
            <Container>
                <Row>
                    <Col className="multi-lineHeading leftHeading pb-3" sm={12}>
                        <span className='dark-h'>Our</span>
                        <span className='light-h'>Expertise</span>
                    </Col>
                    <Col className='textArea'>
                        {expertiseContent[0].contentUp}
                    </Col>
                </Row>
            </Container>
            {/* CARDS SECTION */}
            <Row>
                <ExpertiseCards/>
            </Row>
            {/* CARDS SECTION */}
            <Container className='p-b-50'>
                <Row>
                    <Col className='textArea'>
                        {expertiseContent[0].contentDown}
                    </Col>
                </Row>
            </Container>
        </section>
        {/* EXPERTISE SECTION */}

        {/* OUR PROJECTS SECTION */}
        {ourProjectsData.map((item, index) => (
            <section key={index} id="Work" className='panel-area' style={{background:item.background}}>
                <Container>
                    <Row className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                        {index === 0 ? <Col className="multi-lineHeading" sm={12}>
                            <div className='dark-h'>Our</div>
                            <div className='light-h'>Projects</div>
                        </Col>: ""}
                        <Col className={`align-self-center ${index % 2 === 0 ? 'first-col' : 'second-col'}`} md={{span:7, order: index % 2 === 0 ? '1' : '2' }}>
                            <div className='textArea'>{item.content}</div>
                            {/* <button className={`primary-button ${index % 2 === 0 ? 'secondary-highlighter' : 'primary-highlighter'}`}>{item.buttonText}</button> */}
                        </Col>
                        <Col className={`position-relative text-center ${index % 2 === 0 ? 'text-md-end' : ''}`} md={{span:5, order: index % 2 === 0 ? '2' : '1' }}>
                        <img src={index % 2 === 0 ? arrowRgtImg : arrowLftImg} alt='Arrow Image' className={`arrow d-none d-md-block ${index % 2 === 0 ? 'right' : 'left'}`} />
                        <img src={item.image} alt={item.imgAlt} className='panelImages'/>
                        </Col>
                    </Row>
                </Container>
            </section>
        ))}
        {/* OUR PROJECTS SECTION */}

        {/* MUG SLIDER SECTION */}
        <section className="mug-section bgOffWhite">
            <Container>
                <Row>
                    <Col md={{span:8,offset:2}}>
                        <div className='textArea text-center'>Get ahead of the game with our expertly designed graphic mugs, transforming your daily routine from ordinary to extraordinary. We take your provided content and bring it to life with unique, eye-catching designs, ensuring your day begins as fresh and inspiring as the morning dew. Let our creative designs elevate your mornings and add a touch of personalization to your daily coffee ritual.</div>
                    </Col>
                    <Col className='position-relative'>
                        <img className="arrow sgtUp" src={arrowSgtUp} alt="arrow-up"/>
                        <Carousel/>
                    </Col>
                </Row>
            </Container>
        </section>
        {/* MUG SLIDER SECTION */}

        {/* WORK PROCESS SECTION */}
           <WorkProcess/>
        {/* WORK PROCESS SECTION */}

        {/* WHY US SECTION */}
            <WhyUs/>
        {/* WHY US SECTION */}

        {/* LETS WORK SECTION */}
        <LetsWork 
            message="Let us work together!" 
            repeatCount={2}
            className="letsWork"
            marqueeCount={2} 
        />
          {/* LETS WORK SECTION */}
    </div>
  );
}

export default Home;
