import { Container, Row , Col, Form, Button} from 'react-bootstrap';
import ContactForm from './ContactForm';
import linkedIn from '../img/linkedIn.png';
import mail from '../img/mail.png';
import behance from '../img/behance.png';
import insta from '../img/insta.png';

const Footer = ( ) => {

    return (
    <section className='footer' id='Contact'>
        {/* COPYRIGHT SECTION */}
        <Container>
            <Row className='contactUs py-5'>
                <Col md={6} className='contactContent'>
                    <div className="multi-lineHeading leftHeading pb-3">
                        <span className='dark-h'>Let's</span>
                        <span className='light-h'>Talk</span>
                    </div>
                    <div className='textArea'>Email address:</div>
                    <h4 className='mb-3'>arshita@bdgcreators.com</h4>
                    <div className='textArea'>Social media:</div>
                    <div className="social-links">
                        <a href='https://www.linkedin.com/in/arshita-gumber' target="_blank">
                            <img className="" src={linkedIn} alt="LinkedIn"/>
                        </a>
                        <a href='mailto:arshita@bdgcreators.com' target="_blank">
                            <img className="" src={mail} alt="Mail"/>
                        </a>
                        {/* <a>
                            <img className="" src={behance} alt="Behance"/>
                        </a> */}
                        <a href='https://www.instagram.com/bdgcreatorsdotcom/' target="_blank">
                            <img className="" src={insta} alt="Instagram"/>
                        </a>
                    </div>
                </Col>

                <Col md={6} className="contactForm">
                    <ContactForm/>
                </Col>
            </Row>
        </Container>
        <div className='copyright bgOffWhite p-10 text-center '>
            <i>© 2022 All rights reserved by <b>bdgCreators</b></i>
        </div>
        {/* COPYRIGHT SECTION */}
    </section>

    );
  };
  export default Footer;