
import { Container, Row, Col } from 'react-bootstrap';
import arrowUp from '../img/arrowUp.png';
import { TickerBar } from "./MarqueTransitions";

const Banner = ({ img, heading, lightHeading, content }) => {
    return (
        <section className="banner position-relative">
            <Container>
                <Row className='align-items-end align-items-md-center '>
                    <Col md={7} lg={5} xs={7} className="text-end">
                        {/* Left side with logo */}
                        <div className="img-section">
                            <img src={img} alt="Logo" className="bannerImg" />
                        </div>
                    </Col>
                    <Col className="d-md-none" xs={5}>
                        <div className="name-heading">{heading} <span className='primary-highlighter p-lr-10'>Arshita</span></div>
                        <div className="lightHeading">
                            <img className="arrow up" src={arrowUp} alt="arrow-up"/>
                            {lightHeading}
                        </div>
                    </Col>
                    <Col md={5} lg={7} className="align-self-center">
                        {/* Right side with dynamic menu */}
                        <div className="textArea">
                            <div className="content">{content} <strong><i>INDIA.</i></strong></div>
                        </div>
                        <div className="d-none d-md-block">
                            <div className="name-heading">{heading} <span className='primary-highlighter p-lr-10'>Arshita</span></div>
                            <div className="lightHeading">
                                <img className="arrow up" src={arrowUp} alt="arrow-up"/>
                                {lightHeading}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <TickerBar/>
        </section>
    );
  };
  export default Banner;