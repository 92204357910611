import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import ProcessGraph from '../img/ProcessGraph.png';

const workCards = [
    {
        title: 'Empathize',
        content:'Engage in user research and direct interactions to deeply understand user experiences, needs, and challenges, ensuring a user-centric design foundation.',
    },
    {
        title: 'Define',
        content:'Analyze research findings to create a clear problem statement, guiding focused and effective design efforts.',
    },
    {
        title: 'Ideate',
        content:'Conduct innovative brainstorming sessions to explore a wide range of creative solutions, leveraging diverse perspectives.',
    },
    {
        title: 'Implement',
        content:'Develop prototypes and final products using advanced UX/UI design, web development, and frontend technologies to bring ideas to life.',
    },
    {
        title: 'Test',
        content:'Gather user feedback through usability testing to refine the design, ensuring optimal user experience and project success.',
    }
]

const WorkProcess = () => (
    <section className="work-section">
        <Container>
            <Row>
                <Col className="multi-lineHeading" sm={12}>
                    <div className='dark-h'>Work</div>
                    <div className='light-h'>Process</div>
                </Col>
                <Col className='text-center pt-5' sm={12}>
                    <img className='ProcessGraph' src={ProcessGraph}/>
                </Col>
            </Row>

            <Row>
                {workCards.map((item,index) => (
                    <Col md={{span : 6, offset:index === workCards.length - 1 ? 3 : 0}}>
                        <div className="workSectionCard">
                            <div className='pb-1'><b>{item.title}:</b> </div>
                            {item.content}
                        </div>
                    </Col>
                ))}  
            </Row>
        </Container>
    </section>
)
export default WorkProcess;
