import React, { useState } from 'react';
import { Container, Row, Col, Nav, Navbar } from 'react-bootstrap';

const Header = ({ logo, menuItems }) => {
  const [activeIndex, setActiveIndex] = useState(0);
    const showHome = () => {
        console.log('Home');
        
    }
    const showAbout = () => {
        console.log('About');
    }
    const showWork = () => {
        console.log('Work');
    }
    const showResume = () => {
        console.log('Resume');
        window.open('/pdf-viewer', '_blank');
    }
    const showContact = () => {
        console.log('Contact');
    }
    const handleClick = (index) => {
      setActiveIndex(index); 
        switch(index) {
          case 0:
            showHome();
            break;
          case 1:
            showAbout();
            break;
          case 2:
            showWork();
            break;
          case 3:
            showResume();
            break;
          case 4:
            showContact();
            break;
          default:
            console.log('Other action');
        }
      };

    return (
        // <Container>
        //     <Row>
        //         <div className="position-relative m-t-50">
        //             <header className="header">
        //               <Row className='align-items-center'>
        //                 {/* Left side with logo */}
        //                   <Col className="logo-container" md={3}>
        //                     <img src={logo} alt="Logo" className="logo" />
        //                   </Col>
                  
        //                   {/* Right side with dynamic menu */}
        //                   <Col md={9}>
        //                     <nav className="menu">
        //                       <ul>
        //                           {menuItems.map((item, index) => (
        //                           <li key={index} onClick={() => handleClick(index)} className={index === 0 ? 'active p-lr10-tb-5' : ''}>{item}</li>
        //                           ))}
        //                       </ul>
        //                     </nav>
        //                   </Col>
        //                 </Row>
        //             </header>
        //         </div>
        //     </Row>
        // </Container>
      <Navbar collapseOnSelect expand="lg" className="header">
        <Container>
          <Navbar.Brand href="#home">
            <img src={logo} alt="Logo" className="logo" />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />

          <Navbar.Collapse id="responsive-navbar-nav" placement="end">
            <Nav className="menu ms-auto">
              {menuItems.map((item, index) => (
              <Nav.Link key={index} href={"#" + item} onClick={() => handleClick(index)}
              className={activeIndex === index ? 'active p-lr10-tb-5' : ''}>{item}</Nav.Link>
              ))}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  };
  export default Header;