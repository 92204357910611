import React from 'react';
import { Worker } from '@react-pdf-viewer/core';
import PdfViewer from './PdfViewer';

const PdfViewerPage = () => {
    return (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <PdfViewer />
        </Worker>
    );
};

export default PdfViewerPage;
