import React from 'react';
import { ProgressBar, Viewer, OpenFile } from '@react-pdf-viewer/core';
import { getFilePlugin } from '@react-pdf-viewer/get-file';
import '@react-pdf-viewer/core/lib/styles/index.css';

// const Resume from process.env.PUBLIC_URL + '/Arshita.pdf';

const PdfViewer = () => {
    const renderLoader = (percentages) => (
        <div style={{ width: '240px' }}>
            <ProgressBar progress={Math.round(percentages)} />
        </div>
    );

    const getFilePluginInstance = getFilePlugin({
        fileNameGenerator: (file: OpenFile) => {
            // `file.name` is the URL of opened file
            const fileName = 'ArshitaResume';
            return `${fileName}`;
        },
    });
    const { DownloadButton } = getFilePluginInstance;

    return (
    //     <div
    //     style={{
    //         height: '750px',
    //         width: '70%',
    //         textAlign: 'center',
    //         margin: 'auto',
    //         padding: '50px 0',
        
    //     }}
    //     >
    //         <Viewer
    //         fileUrl={Resume}
    //         renderLoader={renderLoader}
    // />
    // </div>
    <div
    className="rpv-core__viewer"
    style={{
        border: '1px solid rgba(0, 0, 0, 0.3)',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    }}
>
    <div
        style={{
            alignItems: 'center',
            backgroundColor: '#eeeeee',
            borderBottom: '1px solid rgba(0, 0, 0, 0.3)',
            display: 'flex',
            padding: '4px',
        }}
    >
        <DownloadButton />
    </div>
    <div
        style={{
            flex: 1,
            overflow: 'hidden',
            // height: '750px',
            // width: '70%',
            // textAlign: 'center',
            // margin: 'auto',
            padding: '50px 0',
        }}
    >
        <Viewer fileUrl={process.env.PUBLIC_URL + '/Arshita.pdf'} plugins={[getFilePluginInstance]} />
    </div>
</div>
    );
}

export default PdfViewer;